<template>
  <!-- 商品选品 -->
  <div class="shop-num">
    <div class="script-btn">
      <el-button type="primary" style="width: 90px" @click="window = true">添加商品</el-button>
    </div>
    <el-dialog :visible.sync="window" width="30%" @close="closeWindow">
      <template slot="title">
        <div>
          <span class="header-tag-box"></span>
          添加脚本类型
        </div>
      </template>
      <!-- 脚本类型 -->
      <div class="script-openation">
        <span style="margin-right: 10px">行业分类</span>
        <el-cascader v-model="scriptShop" :options="scriptOptions" :props="cateListProps" style="width: 300px" @change="getCategoryShowList"></el-cascader>
      </div>
      <!-- 话术，行业分类标题 -->
      <div class="script-more" style="margin-top: 20px">
        <span style="margin-right: 10px">选择商品</span>
        <!-- 选择商品 -->
        <div class="shop-table">
          <div class="shop-table-title">
            <span>商品名称</span>
            <span>（使用时间）</span>
          </div>
          <el-checkbox-group v-model="shopChecked">
            <el-checkbox v-for="(item, index) in shopCheckList" :key="index" :label="item.id" class="shop-check">{{ item.goods_name }} ( {{ Math.floor(item.answer_time / 60) }}分{{ Math.floor(item.answer_time % 60) }}秒)</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWindow">取 消</el-button>
        <el-button type="primary" @click="addDataFormTable">确 定</el-button>
      </span>
    </el-dialog>
    <span style="font-size: 16px">商品数共计{{ shopList.length }}个</span>
    <div class="script-container">
      <div class="script-header">
        <div class="script-header-item" style="width: 30%; padding-left: 59px">商品名称</div>
        <div class="script-header-item" style="width: 20%">商品数据模板</div>
        <div class="script-header-item" style="width: 20%">点击率</div>
        <div class="script-header-item" style="width: 20%">转化率</div>
        <div class="script-header-item" style="width: 10%">操作</div>
      </div>
      <div class="no-data-icon" v-if="shopList.length == 0">
        <img src="../../../assets/image/no_data.png" alt="" style="margin-bottom: 1px; width: 380px" />
        <span class="no-data-font">暂无数据</span>
      </div>
      <el-scrollbar style="height: 89%" v-if="shopList.length != 0">
        <div class="script-body" v-for="(item, index) in shopList" :key="index">
          <div class="script-body-item" style="width: 30%; padding-left: 45px">
            {{ item.goods_name }}
          </div>
          <div class="script-body-item" style="width: 20%">
            <el-select v-model="item.shopSelect" placeholder="请选择" style="width: 200px" @change="showConversionClick(item.shopSelect, item.goods_id)">
              <el-option v-for="(subItem, subIndex) in item.templateOptions" :key="subIndex" :label="subItem.name" :value="subIndex"> </el-option>
            </el-select>
          </div>
          <div class="script-body-item" style="width: 21%; margin-left: 30px">
            <el-input disabled style="width: 65px" v-model="item.click_rate"></el-input>
            <span style="margin-left: 5px">%</span>
          </div>
          <div class="script-body-item" style="width: 21%">
            <el-input disabled style="width: 65px" v-model="item.change_rate"></el-input>
            <span style="margin-left: 5px">%</span>
          </div>
          <div class="script-body-item" style="width: 11.5%">
            <el-link :underline="false" style="color: red; cursor: pointer" @click="delShopList(item.goods_id)">删除 </el-link>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="getBack">
      <el-button class="getBackBtn" type="primary" @click="backTo">上一步</el-button>
      <el-button class="getBackBtn" type="primary" @click="backNext">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { showGoodListData, commodityList, showTmpData } from "@/utils/apis";
export default {
  name: "newScriptManagementTwo",
  data() {
    return {
      window: false,
      //行业分类
      scriptOptions: [],
      cateListProps: {
        value: "category_id",
        label: "category_name",
        children: "children",
      },
      scriptShop: "全部", //商品下拉框中选中的商品值
      shopCheckList: [], //商品列表
      shopChecked: [], //多选的商品的值
      shopList: [], //商品表格
      shopId: 0,
      editData: "",
    };
  },
  components: {},
  mounted() {
    this.editData = JSON.parse(localStorage.getItem("editData"));
    this.showGoodsList();
    this.showCategoryListData();
  },
  methods: {
    // 根据分类显示商品列表
    getCategoryShowList() {
      let params = {
        goods_class_id: "",
      };
      if (this.scriptShop.length == 1) {
        showGoodListData().then((res) => {
          if (res.code === 200) {
            this.shopCheckList = res.data.list;
          }
        });
      } else {
        this.scriptShop.shift();
        this.scriptShop.forEach((item) => {
          params.goods_class_id = item;
        });
        showGoodListData(params).then((res) => {
          if (res.code === 200) {
            this.shopCheckList = res.data.list;
          }
        });
      }
    },
    // 显示行业分类列表
    showCategoryListData() {
      let params = {
        paging: 0,
      };
      commodityList(params).then((res) => {
        if (res.code === 200) {
          let obj = {
            category_id: "全部",
            category_name: "全部",
          };
          this.scriptOptions = res.data.list;
          this.scriptOptions.unshift(obj);
        }
      });
    },
    // 显示商品分类
    async showGoodsList() {
      let params = {
        paging: 0,
      };
      let res = await showGoodListData(params);
      if (res.code === 200) {
        this.shopCheckList = res.data.list;
        if (this.$route.query.id) {
          this.editScriptData();
        }
      }
    },
    //选择商品后点击确定添加到表格中
    addDataFormTable() {
      let tempRes = this.shopChecked.map((selectItem) => this.shopCheckList.find((item) => item.id == selectItem));
      let arr = tempRes.reduce((acc, cur) => {
        const target = this.shopList.find((item) => item.goods_id == cur.id);
        const temp = target
          ? target
          : {
              id: this.shopId,
              goods_id: cur.id,
              goods_name: cur.goods_name,
              goods_class_id: cur.goods_class_id,
              templateOptions: [
                {
                  name: "模板1",
                  template_id: 1,
                },
                {
                  name: "模板2",
                  template_id: 2,
                },
                {
                  name: "模板3",
                  template_id: 3,
                },
                {
                  name: "模板4",
                  template_id: 4,
                },
                {
                  name: "模板5",
                  template_id: 5,
                },
                {
                  name: "模板6",
                  template_id: 6,
                },
                {
                  name: "模板7",
                  template_id: 7,
                },
                {
                  name: "模板8",
                  template_id: 8,
                },
                {
                  name: "模板9",
                  template_id: 9,
                },
                {
                  name: "模板10",
                  template_id: 10,
                },
              ],
              click_rate: "",
              change_rate: "",
              shopSelect: "",
              answer_time: cur.answer_time,
            };
        return acc.concat(temp);
      }, []);
      if (arr.every((item) => item.answer_time === arr[0].answer_time)) {
        this.shopList = arr;
        this.window = false;
      } else {
        this.$message({
          type: "error",
          message: "请选择使用时间相同的商品",
        });
      }
    },
    // 点击模板显示对应转换率
    showConversionClick(tmpIndex, id) {
      let params = {
        goods_ids: [id],
      };
      showTmpData(params).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            this.shopList.forEach((sItem) => {
              if (item.id == sItem.goods_id) {
                sItem.click_rate = item.template_data[tmpIndex].click_rate;
                sItem.change_rate = item.template_data[tmpIndex].change_rate;
              }
            });
          });
        }
      });
    },
    //   关闭会话框
    closeWindow() {
      let arr = [];
      let total = [];
      // 勾选后未点击确定，直接点击取消后。匹配checked是否包含表格的数据，包含就将表格的数据赋值checked中。
      this.shopList.forEach((item) => {
        total = this.shopChecked.find((ele) => ele == item.goods_id);
        arr.push(total);
      });
      this.shopChecked = arr;
      this.window = false;
    },
    // 删除商品选品列表
    delShopList(id) {
      this.shopList = this.shopList.filter((item) => item.goods_id !== id);
      this.shopChecked = this.shopChecked.filter((item) => item !== id);
      this.$message({
        type: "success",
        message: "删除成功",
      });
    },

    backTo() {
      if (this.$route.query.id) {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/one",
          query: { id: this.$route.query.id },
        });
      } else {
        this.$router.push({
          path: "/scriptManagement/newScriptManagement/one",
          query: { flag: 1 },
        });
      }
    },
    backNext() {
      if (this.shopList.length == 0) {
        this.$message({
          type: "error",
          message: "请添加商品",
        });
      } else {
        let res = this.shopList.find((ele) => ele.shopSelect === "");
        if (res) {
          this.$message({
            type: "error",
            message: "请选择商品数据模板",
          });
        } else {
          let twoForm = [];
          let shopTime = "";
          this.shopList.forEach((item) => {
            let obj = {
              goods_id: item.goods_id,
              template_id: item.shopSelect,
            };
            let time = {
              minute: Math.floor(item.answer_time / 60),
              second: Math.floor(item.answer_time % 60),
            };
            twoForm.push(obj);
            shopTime = time;
          });
          localStorage.setItem("twoForm", JSON.stringify(twoForm));
          localStorage.setItem("shopTime", JSON.stringify(shopTime));
          if (this.$route.query.id) {
            this.$router.push({
              path: "/scriptManagement/newScriptManagement/three",
              query: { id: this.$route.query.id },
            });
          } else {
            this.$router.push({
              path: "/scriptManagement/newScriptManagement/three",
            });
          }
        }
      }
    },

    editScriptData() {
      this.editData.getGoods.forEach((item) => {
        this.shopChecked.push(item.goods_id);
      });
      let arr = this.shopChecked.reduce((acc, cur) => {
        let res = this.shopCheckList.find((ele) => ele.id === cur);
        const goods = this.editData.getGoods.find((item) => item.goods_id == res.id);
        acc.push({
          id: this.shopId,
          goods_id: res.id,
          goods_name: res.goods_name,
          goods_class_id: res.goods_class_id,
          templateOptions: [
            {
              name: "模板1",
              template_id: 1,
            },
            {
              name: "模板2",
              template_id: 2,
            },
            {
              name: "模板3",
              template_id: 3,
            },
            {
              name: "模板4",
              template_id: 4,
            },
            {
              name: "模板5",
              template_id: 5,
            },
            {
              name: "模板6",
              template_id: 6,
            },
            {
              name: "模板7",
              template_id: 7,
            },
            {
              name: "模板8",
              template_id: 8,
            },
            {
              name: "模板9",
              template_id: 9,
            },
            {
              name: "模板10",
              template_id: 10,
            },
          ],
          click_rate: "",
          change_rate: "",
          shopSelect: goods.template_id,
          answer_time: res.answer_time,
        });
        this.showConversionClick(goods.template_id, res.id);
        return acc;
      }, []);
      this.shopList = arr;
    },
  },
};
</script>
<style scoped lang="scss">
.shop-num {
  height: 89%;

  ::-webkit-scrollbar {
    display: none;
  }

  .script-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
  }

  .script-container {
    margin-top: 29px;
    margin-right: 30px;
    height: 89%;
    background: #ffffff;
    border-bottom: 3px solid #f7f8fc;
    border-right: 3px solid #f7f8fc;
    border-left: 3px solid #f7f8fc;
    border-radius: 2px;

    .script-header {
      display: flex;
      align-items: center;
      height: 60px;
      background: #f0f3fc;
    }

    .script-body {
      display: flex;
      overflow-y: auto;

      .script-body-item {
        border: none;
        display: flex;
        align-items: center;
        height: 64px;
        border-bottom: 2px solid #f7f8fc;
      }
    }
  }

  .no-data-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .no-data-font {
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 500;
      color: #a8a8b8;
    }
  }

  .shop-table {
    margin: 15px 35px 0 35px;
    border: 1px solid rgb(233, 232, 232);
    width: auto;
    height: 400px;
    overflow-y: auto;
    border-radius: 5px;

    .shop-table-title {
      padding: 10px 0 0 10px;
      line-height: 20px;
      height: 30px;
      background: #eee;
    }

    .shop-check {
      display: flex;
      align-items: center;
      margin: 20px;
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
  }

  .getBack {
    margin-top: 20px;
    position: absolute;
    bottom: 2%;
    left: 50%;
  }
}
</style>
